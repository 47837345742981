<template>
  <div class="message-section">
    <h2>Stuur een bericht</h2>
    <div class="content-wrapper">
      <div class="notice">
        <p>
          Raboom is een gratis dienst met beperkte klantenservice. Cashbackregistratie is geen waterdichte technologie - in 70% van de gevallen wordt de aankoop juist geregistreerd. Maar het kan gebeuren dat er iets mis gaat tijdens het registratieproces. Door de dienst te gebruiken aanvaard je dit risico. Cashback is nooit gegarandeerd!
        </p>
        <p>
          Mocht je aankoop niet geregistreerd zijn, dan heeft het geen zin om hierover te mailen. Raboom heeft geen mogelijkheid om deze cashback achteraf alsnog te laten registreren.
        </p>
      </div>
      <br/>
      <default-text-input type="white" label="Naam" @inputValue="message.name = $event" :value="message.name" />
      <default-text-input type="white" class="mt-10" label="E-mailadres" @validate="validations.email = $event"
        :validation="validateEmail" warningLabel="Voer een correcte e-mailadres in" @inputValue="message.email = $event"
        :value="message.email" />
      <default-text-input type="white" class="mt-10" :textArea="true" label="Bericht"
        @inputValue="message.message = $event" :value="message.message" />
      <div class="text-center">
        <default-button :disabled="
          !validations.email ||
          !isNotEmpty(message.name) ||
          !isNotEmpty(message.message)
        " @click="sendTrengoMessage" class="mt-10" text="Verstuur" type="gradiant" />
      </div>
    </div>

    <modal v-if="showSendMessage" :close="closeMessage">
      <h1>Je bericht is verzonden!</h1>
      <p>
        Bedankt voor je bericht, we zullen hier zo snel mogelijk op reageren! Er
        is ter bevestiging ook een bericht verzonden naar "{{ message.email }}".
      </p>
    </modal>
  </div>
</template>

<script>
import DefaultButton from "../elements/DefaultButton.vue";
import DefaultTextInput from "../elements/DefaultTextInput.vue";
import Modal from "../patterns/Modal.vue";
import { mapActions } from "vuex";
import { validateEmail, isNotEmpty } from "../../utils/utils";

export default {
  name: "MessageSection",
  components: { DefaultButton, DefaultTextInput, Modal },
  data: () => {
    return {
      message: {
        name: "",
        email: "",
        message: "",
      },
      showSendMessage: false,
      validations: { email: undefined },
    };
  },
  methods: {
    validateEmail,
    isNotEmpty,
    ...mapActions("cashback", ["sendMessage"]),

    async sendTrengoMessage() {

      try {
        const response = await this.sendMessage(this.message);
        if (response.ok) {
          this.showSendMessage = true;
        } else {
          throw new Error()
        }
      } catch {
        this.$toast.error("Er gaat iets mis... probeer het later nog eens.");
      }
    },

    closeMessage() {
      this.showSendMessage = false;


      this.message = {
        message: "",
        name: "",
        email: ""
      };
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: var(--xl4);
  line-height: var(--xl5);
  margin-bottom: 40px;
}

.message-section {
  padding: 140px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f8f9;
  widows: 100%;
  height: 100%;
}

.content-wrapper {
  max-width: 520px;
}
.notice {
  line-height: 1.5;
}
@media only screen and (max-width: 600px) {
  .message-section {
    padding: 40px 0;
  }
}
</style>
