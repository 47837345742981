const questions = [
  {
    question: 'Hoe krijg ik mijn korting?',
    answer: `Stap 1: Registreer je met je e-mailadres. Stap 2: Zoek jouw favoriete webshop en klik op de korting Stap 3: Klik op 'Ga naar webshop' en plaats je bestelling zoals je dit normaal ook zou doen Stap 4: Ontvang geld terug op je account`,
    type: "TOP"
  },
  {
    question: 'Hoe weten jullie dat ik iets gekocht heb?',
    answer: `Als je bent ingelogd en een aankoop wilt doen bij een webshop dan moet je eerst op de speciale ‘Ga naar webshop’ link klikken binnen de korting. Deze link bevat een unieke code, zodat wij altijd weten welke Raboom'er een aankoop heeft gedaan. Je winkelt vervolgens zoals je dit gewend bent in de webshop. Na je aankoop ontvang je van ons direct een bevestiging per e-mail en staat je korting bij 'Mijn account'. Zorg er wel voor dat er geen onderbreking plaatsvindt in het aankoopproces, want dan kunnen wij de korting niet meten. Ook telefonische bestellingen kunnen wij helaas niet meten. Je moet dus altijd shoppen via onze speciale links en online je betaling afronden, zonder onderbreking.`,
    type: "TOP"
  },
  {
    question: 'Wanneer krijg ik mijn cashback uitgekeerd?',
    answer: `De meeste webshops hanteren de wettelijke retourtermijn van 14 dagen. De cashback kan pas uitgekeerd worden wanneer deze termijn is verstreken. Elke webshop hanteert een verschillende retourtermijn. Zodra wij jouw cashback hebben ontvangen van de webshop ontvang je van ons automatisch een e-mail.`,
    type: "TOP"
  },
  {
    question: 'Waar is mijn transactie?',
    answer: `Als jouw cashback niet zichtbaar is, dan heeft de webshop niet kunnen registreren dat je via cashback hebt besteld. Dit kan voorkomen wanneer je geen cookies accepteert, een Adblocker gebruikt of als je na jouw klik bij ons ook nog andere websites hebt bezocht. Heb je wel de juiste stappen doorlopen, maar geen cashback ontvangen? Meld jouw cashback claim dan aan via 'Waar is mijn transactie' in 'Mijn account'`,
    type: "TOP"
  },
  {
    question: 'Waar kan ik een Youtube-video over RABOOM bekijken?',
    answer: `Bekijk hier hoe RABOOM werkt: <a class="link" href="https://youtu.be/cuseCdooRVw" target="_blank">https://www.youtube.com/watch?v=MnMO-BcxhqM</a>`,
    type: "TOP"
  },
  {
    question: 'Zie ik mijn korting direct als ik afreken op de webshop?',
    answer: `Omdat wij pas achteraf geld ontvangen van de webshop, zie je jouw korting niet bij het afrekenen van het bestelde product op de webshop. Indien je bestelling via Raboom bij de webshop geregistreerd is dan staat deze binnen 4 uur in 'Mijn account' onder 'Transacties'.`,
    type: "FAQ"
  },
  {
    question: 'Is de korting ook geldig i.c.m. met aanbiedingen en tijdens uitverkoop van de webshops?',
    answer: `Ja, alle cashback-kortingen zijn geldig i.c.m. aanbiedingen en tijdens de uitverkoop van webshops tenzij anders vermeld in de webshop.`,
    type: "FAQ"
  },
  {
    question: 'Hoe krijg ik het geld van mijn account naar mijn bankrekening?',
    answer: `Als je korting is goedgekeurd door de webshop, dan kun je deze laten uitbetalen. Klik onder ‘Mijn account’ op ‘Uitbetalen’, vul je gegevens in en wij maken het tegoed over op jouw rekening.`,
    type: "FAQ"
  },
  {
    question: 'Hoelang duurt een uitbetaling?',
    answer: `Als je cashback door de webshop is goedgekeurd, kun je je opgebouwde tegoed laten uitbetalen via 'Mijn account'. Je tegoed staat dan binnen 28 dagen op jouw bankrekening.`,
    type: "FAQ"
  },
  {
    question: 'Waarom is mijn transactie nog niet goedgekeurd?',
    answer: `Na je aankoop is je transactie direct zichtbaar onder 'Mijn transacties'. De status van je transactie zal veranderen in 'Goedgekeurd' zodra de webshop waar jij jouw bestelling hebt geplaatst, je bestelling heeft goedgekeurd.`,
    type: "FAQ"
  },
  {
    question: 'Mijn cashback is te laag',
    answer: `Jouw cashback wordt berekend over het aankoopbedrag exclusief BTW en kan hierdoor lager uitvallen dan je misschien van te voren verwachtte.`,
    type: "FAQ"
  },
  {
    question: 'Geldt cashback ook op aankopen in de winkel?',
    answer: `Nee. Je kunt geen cashback ontvangen op aankopen in de winkel. Om cashback te krijgen moet je via deze website je bestelling bij de webshop plaatsen. Je kunt er dan wel voor kiezen om je bestelling in de winkel op te halen.`,
    type: "FAQ"
  },
  {
    question: 'Ik ben cashback vergeten te gebruiken, kan ik dit nog krijgen?',
    answer: `Helaas is dit niet mogelijk. Het is echt belangrijk dat je voordat je iets gaat bestellen bij een webshop, via deze website naar de webshop klikt. Wij ontvangen anders geen commissie en kunnen jouw cashback hierdoor niet uitbetalen.`,
    type: "FAQ"
  },
  {
    question: 'Ik ben geregistreerd in een verkeerd land, wat nu?',
    answer: `Kan gebeuren! Stuur via onderstaand contactformulier een mailtje. We kunnen jouw account dan direct omzetten naar .nl of .be.`,
    type: "FAQ"
  },
  {
    question: 'Waarom duurt het goedkeuren van mijn bestelling langer dan aangegeven?',
    answer: `De status 'in behandeling' blijft staan totdat de webshop jouw bestelling heeft beoordeeld. Als je klikt op 'in behandeling' kun je zien hoelang dit gemiddeld duurt. Dit blijft een indicatie. Wij kunnen de keuring van jouw cashback niet versnellen.`,
    type: "FAQ"
  }
]


export default questions