<template>
  <div class="w-full relative">
    <label>{{ label }}</label>
    <input ref="inputRef" :class="{ warning: !noErrors, ['text']: true }"
           class="default-text-input" type="text" @blur="$emit('blur')" @focus="$emit('focus')"
           :placeholder="placeholder"
    />
    <p v-if="!noErrors" class="warning-text">
      {{ !warningLabel ? "" : warningLabel }}
      <slot v-if="!warningLabel" name="warning" />
    </p>
  </div>

</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "DefaultCurrencyInput",
  props: {
    modelValue: Number, // Vue 2: value
    options: Object,
    warningLabel: String,
    isValid: Boolean,
    label: String,
    placeholder: String,
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
  computed: {
    noErrors() {
      return this.isValid
    },
  }
};
</script>
<style scoped>
.default-text-input {
  height: 56px;
  border-radius: 8px;
  padding: 20px;
  background-color: #f5f8f9;
  width: 100%;
  margin-top: 16px;
}


input[type="text"]:focus {
  border: 1px solid #1d9ef9;
  border-radius: 4px;
  outline-color: #1d9ef9;
}


.warning {
  border: 1px solid red;
  outline-color: var(--red);
}

input[type="text"].warning:focus {
  border: 1px solid red;
  outline-color: var(--red);
}

.warning-text {
  color: var(--red);
  font-weight: bold;
}

label {
  color: #0b0000;
  font-size: var(--m);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}
</style>