<template>
  <fragment>
    <dynamic-header title="Waar is mijn transactie?" />

    <div class="container mx-auto claims-page">
      <h1>Uitleg claims</h1>
      <p>
        In 70% van de gevallen worden bestellingen automatisch geregistreerd.
        Als jouw cashback 24 uur na het plaatsen van de bestelling niet
        automatisch zichtbaar is in jouw account, dan kun je hier een claim
        indienen. Deze wordt doorgestuurd naar de webshop en binnen 6 weken in
        behandeling genomen.
      </p>
      <p>
        Controleer eerst of hiervoor toch niet een goede rede geweest kan zijn.
        Vaak is dat namelijk het geval. Onderzoek heeft uitgewezen dat het niet
        registreren van een cashback zelden ligt aan de webshop of aan ons, maar
        bijna altijd aan de gebruiker.
      </p>
      <p>
        Wij bieden je de service om voor jou uit te zoeken of je de cashback
        alsnog kunt ontvangen. Wanneer je de cashback na zo'n claim alsnog
        ontvangt, doet de webshop dit vaak om jou tegemoet te komen. Het
        indienen van een claim is dus geen garantie dat jij cashback ontvangt.
        Als de webshop jouw verzoek afwijst, dan is dit bindend.
      </p>
      <h3 class="font-semibold">Voorwaarden:</h3>
      <ul class="ml-5">
        <li class="list-disc">
          Bestelling is minimaal 24 uur en maximaal drie weken oud;
        </li>
        <li class="list-disc">Bestelling is geplaatst via ons;</li>
        <li class="list-disc">
          Indienen van een cashback claim is geen garantie dat de cashback ook
          uitbetaald wordt;
        </li>
        <li class="list-disc">
          Bij het indienen van ongeldige cashback claims, kan deze functie voor
          jou geblokkeerd worden.
        </li>
      </ul>
      <p>
        Wij dienen de claim in o.b.v. de gegevens die jij aanlevert, dus zorg
        ervoor dat je gevraagde informatie juist invult.
      </p>
      <p>
        De volgende webshops accepteren geen claims: ABOUT YOU, Aliexpress,
        Booking.com en Smartphonehoesjes.
      </p>

      <h1>Claim formulier</h1>
      <p>
        Vul het onderstaande formulier in om je claim in te dienen.
      </p>
      <form @submit.prevent="createTicket">
        <div class="form-group">
          <label>Onderwerp*</label>
          <select v-model="claim.type" name="type" :class="{ warning: validationErrors.type, ['text']: true }">
            <option v-if="false" value="cashbackAmountInvalid">
              Verkeerd cashback bedrag
            </option>
            <option value="purchaseNotRegistered">
              Transactie niet geregistreerd
            </option>
            <option v-if="false" value="purchaseRejected">Transactie afgekeurd</option>
          </select>
          <p v-if="validationErrors.type" class="warning-text">
            {{ !validationErrors.type ? "" : validationErrors.type }}
            <slot v-if="validationErrors.type" name="warning" />
          </p>
        </div>

        <div class="form-group">
          <label>Webwinkel*</label>
          <select v-model="claim.storeId" name="storeId" :class="{ warning: validationErrors.storeId, ['text']: true }">
            <option
              :value="store.storeId"
              v-for="store in stores"
              v-bind:key="store.storeId"
            >
              {{ store.name }}
            </option>
          </select>
          <p v-if="validationErrors.storeId" class="warning-text">
            {{ !validationErrors.storeId ? "" : validationErrors.storeId }}
            <slot v-if="validationErrors.storeId" name="warning" />
          </p>


        </div>
        <default-text-input
            :warningLabel="validationErrors.purchaseDate"
            class="mt-3"
            @inputValue="claim.purchaseDate = $event"
            :label="'Order Datum*'"
            :value="claim.purchaseDate"
            inputType="date"
            name="purchaseDate"
            :isManualValid="!validationErrors.purchaseDate"
        />
        <default-text-input
            :warningLabel="validationErrors.orderNumber"
            class="mt-3"
            @inputValue="claim.orderNumber = $event"
            :label="'Order Nummer*'"
            :value="claim.orderNumber"
            name="orderNumber"
            :isManualValid="!validationErrors.orderNumber"
            placeholder="Vul hier het order nummer in"
        />

        <DefaultCurrencyInput
          name="purchaseAmount"
          class="mt-3"
          placeholder="Vul hier het bedrag in van de order"
          v-model="claim.purchaseAmount"
          :options="{ locale: 'nl-NL', currency: 'EUR', precision: 2 }"
          :isValid="!validationErrors.purchaseAmount"
          label="Order Bedrag*"
          :warningLabel="validationErrors.purchaseAmount"
        />

        <div class="form-group mt-3">
          <label>Factuur*</label>
          <input name="attachment" type="file" @change="onFileChange" ref="file" accept=".jpg,.png,.pdf" :class="{ warning: validationErrors.attachment, ['file']: true }"/>
          <p v-if="validationErrors.attachment" class="warning-text">
            {{ !validationErrors.attachment ? "" : validationErrors.attachment }}
            <slot v-if="validationErrors.attachment" name="warning" />
          </p>
        </div>

        <default-text-input
            :warningLabel="validationErrors.remarks"
            class="mt-3"
            @inputValue="claim.remarks = $event"
            :label="'Opmerking*'"
            :value="claim.remarks"
            name="remarks"
            placeholder="Vul hier je opmerking in indien van toepassing"
            :isManualValid="!validationErrors.remarks"
        />

        <default-button class="mt-3" text="Verstuur Claim" type="gradient" :loading="submitted" :disabled="submitted"/>
      </form>
    </div>
    <footer-component />
  </fragment>
</template>

<script>
import DefaultButton from "../components/elements/DefaultButton.vue";
import DefaultTextInput from "../components/elements/DefaultTextInput.vue";
import DefaultCurrencyInput from "../components/elements/DefaultCurrencyInput.vue";

import DynamicHeader from "../components/patterns/DynamicHeader";
import FooterComponent from "../components/patterns/FooterComponent.vue";
// import Modal from "../components/patterns/Modal.vue";
import { mapGetters } from "vuex";

export default {
  name: "Claims",
  metaInfo: {
    titleTemplate: "%s | Claims",
  },
  components: {
    DefaultCurrencyInput,
    DefaultTextInput,
    FooterComponent,
    DynamicHeader,
    DefaultButton,
  },
  data: function() {
    return {
      btnDisabled: false,
      btnText: undefined,
      claim: {},
      file: undefined,
      submitted: false,
      validationErrors: {},
      validations: {
        purchaseDate: undefined,
      },
    };
  },
  computed: {
    ...mapGetters("cashback", ["stores"]),
  },
  methods: {
    validateForm() {
      const errors = {};
      if (!this.claim.type) errors.type = "Onderwerp is verplicht.";
      if (!this.claim.storeId) errors.storeId = "Selecteer een webwinkel.";
      if (!this.claim.purchaseDate) {
        errors.purchaseDate = "Order datum is verplicht.";
      }else {
        const currentDate = new Date();
        const purchaseDate = new Date(this.claim.purchaseDate);
        if ((currentDate - purchaseDate) / (1000 * 60 * 60) < 48) {
          errors.purchaseDate = "Order datum mag niet binnen 48 uur zijn.";
        }
      }
      if (!this.claim.orderNumber) errors.orderNumber = "Order nummer is verplicht.";
      if (!this.claim.remarks) errors.remarks = "Opmerking is verplicht.";
      if (!this.claim.purchaseAmount || this.claim.purchaseAmount <= 0)
        errors.purchaseAmount = "Vul een geldig bedrag in.";
      if (!this.file) errors.attachment = "Factuur is verplicht.";
      this.validationErrors = errors;
      return Object.keys(errors).length === 0;
    },
    onFileChange (e) {
      this.file = e.target.files[0];
    },
    async createTicket () {
      this.submitted = true
      if (!this.validateForm()) {
        this.submitted = false;
        return;
      }
      const formData = new FormData();

      formData.append('purchaseAmount', this.claim.purchaseAmount)
      formData.append('type', this.claim.type)
      formData.append('storeId', this.claim.storeId)
      formData.append('purchaseDate', this.claim.purchaseDate)
      formData.append('orderNumber', this.claim.orderNumber)
      formData.append('remarks', this.claim.remarks)
      formData.append("file", this.file);

      const response = await this.$store.dispatch("cashback/createTicket", formData);
      if (response.ok) {
        this.$toast.success("Je claim is succesvol ingediend en je ontvangt zo spoedig mogelijk bericht.");
        this.claim = {}
        this.file = undefined
        // Use nextTick to ensure DOM updates have finished
        this.$nextTick(() => {
          if (this.$refs.file) {
            this.$refs.file.value = null;
          }
        });
      } else {
        this.$toast.error("Er is iets mis gegaan met het indienen van je claim; controleer de velden.");
      }
      this.submitted = false
    }
  },
  async beforeMount() {
    await this.$store.dispatch("cashback/getStores");
  },
};
</script>

<style scoped>
h1 {
  font-size: var(--xl3);
  line-height: var(--xl2);
  margin-bottom: 10px;
}

p {
  padding: 20px 0;
}

.claims-page {
  padding: 80px 15px;
  height: 100%;
}
.warning {
  border: 1px solid red;
  outline-color: var(--red);
}

input[type="text"].warning:focus {
  border: 1px solid red;
  outline-color: var(--red);
}

.warning-text {
  color: var(--red);
  font-weight: bold;
}
.button-container {
  padding: 20px 0;
  widows: 100%;
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.form-group label {
  color: #0b0000;
  font-size: var(--m);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.form-group input[type="text"],
.form-group select,
.form-group input[type="file"],
.form-group input[type="date"] {
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: #f5f8f9;
  width: 100%;
  margin-top: 12px;
  border-right: 16px solid transparent;
}

.form-group input[type="text"]:focus,
.form-group select:focus {
  border-right: 16px solid transparent;
  border-radius: 4px;
  outline-color: #1d9ef9;
}

@media only screen and (max-width: 600px) {
  .claims-page {
    height: 100%;
  }
}
</style>
